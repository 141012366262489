@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply transition-colors duration-500 ease-in-out font-mono;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body.dark {
    @apply bg-dark text-white;
  }

  body.light {
    @apply bg-white text-gray-900;
  }

  body.ghibli {
    @apply text-white;
    position: relative;
  }

  body.ghibli::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/ghibli.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    transition: opacity 0.6s ease;
  }
  
  /* Theme transition effects */
  body.theme-transitioning::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    pointer-events: none;
    animation: themeFade 0.6s ease-in-out;
  }
  
  @keyframes themeFade {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

  /* Ghibli theme font and color adjustments */
  body.ghibli h1, 
  body.ghibli h2, 
  body.ghibli h3 {
    font-family: 'Indie Flower', cursive;
    letter-spacing: 0.02em;
  }

  body.ghibli a:hover {
    color: rgba(255, 196, 145, 0.9); /* Warm orange glow on hover for links */
    transition: color 0.3s ease;
  }

  body.ghibli button:hover {
    color: rgba(255, 196, 145, 0.9); /* Warm orange glow on hover for buttons */
    transition: color 0.3s ease;
  }

  body.ghibli .bg-dark, 
  body.ghibli .bg-black,
  body.ghibli .bg-black\/40,
  body.ghibli .bg-black\/90 {
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(12px);
    border-color: rgba(255, 255, 255, 0.12) !important;
    transition: background-color 0.5s ease, backdrop-filter 0.5s ease;
  }

  body.ghibli .text-gray-400,
  body.ghibli .text-gray-500 {
    @apply text-white/80;
  }
}

@layer utilities {
  .font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  }
}

/* Update the transition speed in the global styles */
* {
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
}

/* Add these classes to your existing CSS */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Ghibli dust/leaf animation */
@keyframes floatingDust {
  0% { transform: translateY(0) translateX(0) rotate(0deg); opacity: 0; }
  10% { opacity: 0.8; }
  90% { opacity: 0.6; }
  100% { transform: translateY(-100vh) translateX(60px) rotate(360deg); opacity: 0; }
}

.ghibli-dust {
  position: fixed;
  bottom: -20px;
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  pointer-events: none;
  z-index: 5;
  animation: floatingDust 15s infinite linear;
}

.ghibli-dust:nth-child(2n) {
  width: 4px;
  height: 4px;
  animation-duration: 20s;
  animation-delay: 2s;
  left: 20%;
}

.ghibli-dust:nth-child(3n) {
  width: 5px;
  height: 5px;
  animation-duration: 18s;
  animation-delay: 4s;
  left: 40%;
}

.ghibli-dust:nth-child(4n) {
  width: 3px;
  height: 3px;
  animation-duration: 25s;
  animation-delay: 7s;
  left: 60%;
}

.ghibli-dust:nth-child(5n) {
  width: 4px;
  height: 4px;
  animation-duration: 22s;
  animation-delay: 5s;
  left: 80%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -100% 50%;
  }
}

.animate-shimmer {
  animation: shimmer 2s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}